import React from 'react'
import '../Styles/Sections/home.css'
import ButtonLink from '../Components/ButtonLink'
import sedeWebm from '../Assets/cidade.webm'
import sedeMp4 from '../Assets/cidade.mp4'


function Home() {
  return (
    <section id='home-section' className='flex-center padding-50'>
            <video className="background-video" autoPlay muted loop  playsInline loading="lazy">
                <source src={sedeMp4} type="video/mp4"/>
                <source src={sedeWebm} type="video/webm"/>
            </video>
        <div className="content flex-center flex-column flex__gap-50">
            <div className='flex-center flex-column flex__gap-30'>
                <h1 className='title-main'>Conheça o <span>Grupo Fixx</span></h1>
                <p className='paragraph-default text-center'>
                O Grupo Fixx é uma referência no mercado brasileiro, atuando em setores estratégicos como financeiro, tecnológico, agropecuário, automotivo e alimentício. Com mais de 12 empresas interconectadas e uma trajetória de 13 anos de expertise, o grupo se destaca por soluções inovadoras, representadas por empresas como Fixxcapital, Fixxbank, Agro Fixx, Nexxfixx e Chicken In House. Recentemente, o Grupo recebeu a certificação da CVM, conforme noticiado pelo G1 e em diversos outros portais nacionais, consolidando sua força no setor financeiro brasileiro e internacional, ampliando suas possibilidades no mercado global. Este reconhecimento atesta sua governança sólida e transparente, reforçando a confiança de investidores e parceiros estratégicos. Com sede no Paraná e atuação em diversos estados e países, o Grupo Fixx segue sua trajetória de crescimento contínuo, liderado por uma visão ambiciosa que impulsiona sua reputação como um dos mais promissores e respeitados do Brasil.
                </p>
            </div>

            <div id='container-links' className='flex-center flex-column flex__gap-20'>
                <span className='text-center'>Para saber mais sobre o que estamos construindo e como você pode fazer parte dessa transformação, visite nossos sites:</span>
                <div className='flex-center flex__gap-20 flex-wrap'>
                    <ButtonLink 
                    link="https://fixxi.com.br/"
                    text="Acessar Fixxinvest"
                    />
                    <ButtonLink 
                    link="https://fixxbank.com.br/"
                    text="Acessar Fixxbank"
                    />
                    <ButtonLink 
                    text="Acessar Fixxcapital"
                    showMessageClick={true}
                    />
              </div>
        </div>
        </div>

    </section>
  )
}

export default Home
