import React, { useState } from 'react'
import '../Styles/Components/btn-link.css'


function ButtonLink({link, text, showMessageClick}) {
  const [message, setMessage] = useState(false)

  const showMessage = () => {
    setMessage(true)
    setTimeout(() => {
      setMessage(false)
    }, 3000)
  }

  return (
    <a href={link} target='_blanck' rel='noopener noreferrer'>
        <button onClick={showMessageClick ? showMessage : null} className='btn-link'>{text}</button>
        {
          message && (
            <div className='content-message'>
              <span className='message'>Em atualização</span>
            </div>
          )
        }
    </a>
  )
}

export default ButtonLink
